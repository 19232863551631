/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    pre: "pre",
    code: "code",
    strong: "strong",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "custom-transformations",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#custom-transformations",
    "aria-label": "custom transformations permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Custom transformations"), "\n", React.createElement(_components.p, null, "Extend the core of SVGR to creates complex transformations."), "\n", React.createElement("carbon-ad"), "\n", React.createElement(_components.h2, {
    id: "install",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#install",
    "aria-label": "install permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Install"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "npm install @svgr/plugin-jsx\n# or use yarn\nyarn add @svgr/plugin-jsx\n")), "\n", React.createElement(_components.h2, {
    id: "usage",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#usage",
    "aria-label": "usage permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Usage"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, ".svgrrc")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n  \"plugins\": [\"@svgr/plugin-jsx\"]\n}\n")), "\n", React.createElement(_components.h2, {
    id: "how-does-it-work",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-does-it-work",
    "aria-label": "how does it work permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How does it work?"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "@svgr/plugin-jsx"), " consists in three phases:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Parsing the SVG code using ", React.createElement(_components.a, {
    href: "https://github.com/Rich-Harris/svg-parser"
  }, "svg-parser")), "\n", React.createElement(_components.li, null, "Converting the ", React.createElement(_components.a, {
    href: "https://github.com/syntax-tree/hast"
  }, "HAST"), " into a ", React.createElement(_components.a, {
    href: "https://github.com/babel/babel/blob/master/packages/babel-parser/ast/spec.md"
  }, "Babel AST")), "\n", React.createElement(_components.li, null, "Applying ", React.createElement(_components.a, {
    href: "../babel-preset/README.md"
  }, React.createElement(_components.code, null, "@svgr/babel-preset")), " transformations"), "\n"), "\n", React.createElement(_components.h2, {
    id: "applying-custom-transformations",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#applying-custom-transformations",
    "aria-label": "applying custom transformations permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Applying custom transformations"), "\n", React.createElement(_components.p, null, "You can extend the Babel config applied in this plugin using ", React.createElement(_components.code, null, "jsx.babelConfig"), " config path:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// .svgrrc.js\n\nmodule.exports = {\n  jsx: {\n    babelConfig: {\n      plugins: [\n        // For an example, this plugin will remove \"id\" attribute from \"svg\" tag\n        [\n          '@svgr/babel-plugin-remove-jsx-attribute',\n          {\n            elements: ['svg'],\n            attributes: ['id'],\n          },\n        ],\n      ],\n    },\n  },\n}\n")), "\n", React.createElement(_components.p, null, "Several Babel plugins are available:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/gregberge/svgr/blob/master/packages/babel-plugin-add-jsx-attribute/README.md"
  }, React.createElement(_components.code, null, "@svgr/babel-plugin-add-jsx-attribute"))), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/gregberge/svgr/blob/master/packages/babel-plugin-remove-jsx-attribute/README.md"
  }, React.createElement(_components.code, null, "@svgr/babel-plugin-remove-jsx-attribute"))), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/gregberge/svgr/blob/master/packages/babel-plugin-remove-jsx-empty-expression/README.md"
  }, React.createElement(_components.code, null, "@svgr/babel-plugin-remove-jsx-empty-expression"))), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/gregberge/svgr/blob/master/packages/babel-plugin-replace-jsx-attribute-value/README.md"
  }, React.createElement(_components.code, null, "@svgr/babel-plugin-replace-jsx-attribute-value"))), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/gregberge/svgr/blob/master/packages/babel-plugin-svg-dynamic-title/README.md"
  }, React.createElement(_components.code, null, "@svgr/babel-plugin-svg-dynamic-title"))), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/gregberge/svgr/blob/master/packages/babel-plugin-svg-em-dimensions/README.md"
  }, React.createElement(_components.code, null, "@svgr/babel-plugin-svg-em-dimensions"))), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/gregberge/svgr/blob/master/packages/babel-plugin-transform-react-native-svg/README.md"
  }, React.createElement(_components.code, null, "@svgr/babel-plugin-transform-react-native-svg"))), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/gregberge/svgr/blob/master/packages/babel-plugin-transform-svg-component/README.md"
  }, React.createElement(_components.code, null, "@svgr/babel-plugin-transform-svg-component"))), "\n"), "\n", React.createElement(_components.p, null, "If you want to create your own, reading ", React.createElement(_components.a, {
    href: "https://github.com/jamiebuilds/babel-handbook/blob/master/translations/en/plugin-handbook.md"
  }, "Babel Handbook"), " is a good start!"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
